import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import UnstyledLayout from "../../components/unstyled-layout";
import Seo from "../../components/seo";
export const _frontmatter = {
  "title": "Cookie Policy"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = UnstyledLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} pathname={props.location.pathname} mdxType="Seo" />
    <p>{`This Cookie Policy of Notificare explains in which way cookies are stored on and information is read from your computer, tablet and/or mobile phone (hereafter: “Terminal Equipment”) through the website of Notificare. This Cookie Policy also states for which purposes the collected information will be used. We thus advise you to read this Cookie Policy carefully.`}</p>
    <h3>{`What are Cookies?`}</h3>
    <p>{`Cookies are small (text) files that are sent to your browser while you are visiting the website or using the service of Notificare and are subsequently stored on the hard drive or in the memory of your Terminal Equipment. At your next visit to the website, the browser can send back this information. The cookies that are stored through this website cannot harm your Terminal Equipment or any files that are saved on your Terminal Equipment.`}</p>
    <h3>{`Which cookies are used by Notificare and for which purpose?`}</h3>
    <h4>{`Notificare’s Cookies`}</h4>
    <p>{`This paragraph applies to those cookies that are stored and read by Notificare itself. No third parties are involved in storing and reading these so-called first party cookies.`}</p>
    <p>{`Firstly, Notificare stores technical cookies. These cookies are necessary to provide the requested service. For instance, technical cookies allow you to proceed through different pages of a website with a single login and they remember selections in (order)forms that are filled out on the website.`}</p>
    <p>{`Besides technical cookies, Notificare stores cookies that are only stored on your Terminal Equipment temporarily. These cookies are automatically erased when you close your browser. These so called session cookies are used to map your online behaviour during a visit to the website. Notificare can use this information to adjust its website to the needs of its users.`}</p>
    <p>{`Also, Notificare can store cookies that remain stored on your Terminal Equipment for a longer period of time. With these permanent cookies your Terminal Equipment can be recognized the next time you visit the website. These cookies enable Notificare to adjust the website to your specific preferences. Since your preferences are already set, your next visit will be less time-consuming and more agreeable.`}</p>
    <p>{`Furthermore, Notificare uses permanent cookies to track your online behaviour. Notificare uses these tracking cookies to register your behaviour on the website and to present you with offers specifically tailored to you based on this information.`}</p>
    <p>{`Reading information without cookies
Besides storing cookies, Notificare reads information from your browser and Terminal Equipment without a cookie being stored. In this way, Notificare collects the (technical) features of your Terminal Equipment and software used by you, such as the type of operating system, the browser settings, the installed plug-ins, the time zone and the screen size. Notificare uses this information to track your online behavior.`}</p>
    <h4>{`Third party cookies`}</h4>
    <p>{`Apart from cookies that Notificare itself stores, third parties can store cookies on your Terminal Equipment through the website. This paragraph applies to these third party cookies. Notificare can not control or influence the use of these cookies and/or the use by third parties of the information collected through these cookies. Notificare only provides the platform that enables storing these third party cookies. Notificare emphasizes that on the use of the data collected through these cookies further conditions of third parties may be applicable. The different types of third party cookies that are stored through the website are discussed below.`}</p>
    <h4>{`Google Analytics`}</h4>
    <p>{`In order to collect data on the usage of the website and visits to the website, Notificare uses Google Analytics. Google Analytics stores a permanent cookie on your Terminal Equipment which is subsequently used to register your use of the website. This data is then analyzed by Google and the results are given to Notificare. This enables Notificare to get more insight in the way in which the website is used and, based on this information, to make adjustments to the website or the provided services.`}</p>
    <p>{`Google can give this information to third parties if it is legally obligated to do so and/or if third parties are processing the information on behalf of Google. Google can not use the information for other Google services.`}</p>
    <h4>{`Social plug ins`}</h4>
    <p>{`The website is linked to various social media, including but not limited to Facebook, Twitter, LinkedIn and Google+. Through the use of these social plug ins you can share and recommend information to others. Through the social plug ins, third party cookies are stored on your Terminal Equipment. These are cookies that are used to optimize your experience of the website. Also, tracking cookies may be stored that are used to track your online behaviour when using various websites and to build a profile of your online behavior.`}</p>
    <p>{`Besides that, videos are shown on the website that come from third party websites, including You Tube. These third parties store cookies that make it possible to show you the videos. Also, tracking cookies may be stored that are used to track your online behaviour when using various websites and to build a profile of your online behavior.`}</p>
    <h4>{`Deleting cookies`}</h4>
    <p>{`The consent you have given to store and read first and third party cookies, can be withdrawn at any time by setting your browser to disable cookies and/or to remove all cookies from your browser.`}</p>
    <p>{`By clicking the links below, you can read how you can delete the different types of cookies from different browsers.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://support.google.com/chrome/bin/answer.py?hl=nl&answer=95647"
        }}>{`Chrome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
        }}>{`Firefox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://windows.microsoft.com/nl-NL/windows7/Change-Internet-Explorer-9-Privacy-settings"
        }}>{`Internet Explorer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://support.apple.com/kb/PH5042"
        }}>{`Safari`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://help.opera.com/Windows/12.10/en/cookies.html"
        }}>{`Opera`}</a></li>
    </ul>
    <p>{`Please take into account that deleting cookies may cause some parts of the website to work incorrectly.`}</p>
    <h4>{`Privacy Policy`}</h4>
    <p>{`It is possible that information collected through a cookie or obtained otherwise, contains personal data. If this is the case, the Privacy Policy of Notificare is applicable on the processing of these data. The Privacy Policy can be read `}<a parentName="p" {...{
        "href": "http://notifica.re/privacy"
      }}>{`here`}</a>{`.`}</p>
    <h4>{`Changes`}</h4>
    <p>{`This Cookie Policy can be changed at all times. Changes in the Cookie Policy will be announced on the website.`}</p>
    <h4>{`Questions`}</h4>
    <p>{`If you have any further questions about this Cookie Policy, you can send an e-mail to `}<a parentName="p" {...{
        "href": "mailto:support@notifica.re"
      }}>{`support@notifica.re`}</a>{` You can also find additional information on the use of cookies on the website of the Dutch consumers' association.`}</p>
    <p><strong parentName="p">{`Last modified: 1 March 2013`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      